<template>
    <v-sheet>
        <v-row>
            <v-col cols="12" sm="7" md="8">
                <p class="exie-text-h4 mb-5">Resume Center</p>
            </v-col>
        </v-row>
        
        <v-row class="mb-5 pb-0">
            <v-col cols="12" md="7">
                <p class="text-description text-medium-emphasis mb-1">Our Resume Checker helps you better highlight your skills and results in the ATS-compliant format</p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" md="4">
                <v-card class="bg-deep-orange-lighten-5 pa-5 d-flex flex-column" :style="{height: '100%', minHeight: '327px'}" rounded="xl" flat>
                    <p class="exie-text-h5">Resume Checker</p>
                    <p class="exie-text-body-1 mt-3">
                        A screening interview is the first step in the hiring process, aimed at determining whether a candidate has the skills, experience, and mindset required for the role.
                    </p>
                    <p class="mt-auto">
                        <v-btn variant="flat" color="black" class="rounded-16" :to="{name: 'resume-checker'}">Check My Resume</v-btn>
                    </p>
                </v-card>
            </v-col>
            <v-col cols="6" md="4" v-if="false">
                <v-card class="bg-deep-orange-lighten-5 pa-5 d-flex flex-column" :style="{height: '100%', minHeight: '327px'}" rounded="xl" flat>
                    <p class="exie-text-h5">Resume Matcher</p>
                    <p class="exie-text-body-1 mt-3">
                        Prepare a personalized resume for a specific vacancy. This will allow you to highlight your strengths that are required for a specific vacancy and pass ATS (Applicant tracking software).
                    </p>
                    <p class="mt-auto">
                        <v-btn variant="flat" color="black" class="rounded-16">Match My Resume</v-btn>
                    </p>
                </v-card>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    setup() {

    }
}
</script>