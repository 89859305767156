const interviews = [
    {
        title: 'Backend Developer',
        value: 'backend_developer',
        show: true,
        legacy: true
    },
    {
        title: 'Frontend Developer',
        value: 'frontend_developer',
        show: true,
        legacy: true
    },
    {
        title: 'Product Analyst',
        value: 'product_analyst',
        show: true,
        legacy: true
    },
    {
        title: 'Data Scientist',
        value: 'data_scientist',
        show: true,
        legacy: true
    },
    {
        title: 'QA Engineer',
        value: 'qa_engineer',
        show: true,
        legacy: true
    },
    {
        title: 'Project Manager',
        value: 'project_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Product Manager',
        value: 'product_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Product Designer',
        value: 'product_designer',
        show: true,
        legacy: true
    },
    {
        title: 'Product Designer (Test)',
        value: 'product_designer_test',
        test: true,
        show: true,
        legacy: true
    },
    {
        title: 'Marketing Manager (Test)',
        value: 'marketing_manager_test',
        test: true,
        legacy: true
    },
    {
        title: 'Product Marketing Manager (Test)',
        value: 'product_marketing_manager_test',
        test: true,
        legacy: true
    },
    {
        title: 'Marketing Manager',
        value: 'marketing_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Product Marketing Manager',
        value: 'product_marketing_manager',
        show: true,
        legacy: true
    },
    {
        title: 'Screening',
        value: 'design_screening'
    },
    {
        title: 'Self Assessment',
        value: 'design_self_assessment'
    },
]

const languages = [
    { title: 'English', value: 'english'},
    { title: 'Español', value: 'spanish'},
    { title: 'Português', value: 'portuguese'},
    { title: 'Français', value: 'french'},
    { title: 'Italiano', value: 'italian'},
    { title: 'Deutsch', value: 'german'},
    { title: 'Русский', value: 'russian'},
]

const getInterviewTitle = (value) => {
    const interview = interviews.find(interview => interview.value === value)
    return interview.title
}

const getLanguageTitle = (value) => {
    const language = languages.find(language => language.value === value)
    if(language) {
        return language.title
    }
    return ''
}

export { interviews, getInterviewTitle, languages, getLanguageTitle }